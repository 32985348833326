import Two from 'two.js';

import APP from './mutilator';

const fish = () => {
  const $container = document.querySelector('[data-controller="fish"]');
  const { innerWidth, innerHeight } = window;
  // const toDegrees = (n) => n * (180 / Math.PI);
  const toRadians = (n) => n * (Math.PI / 180);
  const radius = innerWidth / 7;
  const maxSpeed = 2.25;
  let angle = 0;
  let orientation = 0;
  let hover = false;
  let isInRadius = false;
  let isMouseActive = true;
  let isRadiusEntered = false;
  let timer;

  const two = new Two({
    type: Two.Types['svg'],
    fullscreen: false,
    autostart: true
  }).appendTo($container);

  function resize() {
    two.width = innerWidth;
    two.height = innerHeight;
    two.renderer.setSize(innerWidth, innerHeight);
  }

  window.addEventListener('resize', resize, false);
  resize();

  const mouse = new Two.Vector();
  const acceleration = new Two.Vector();
  const velocity = new Two.Vector();
  const position = new Two.Vector();
  const destination = new Two.Vector();
  const circling = new Two.Vector();
  const delta = new Two.Vector();

  const r1 = two.makeSprite('/user/themes/karpuchina/assets/img/fish.svg', two.width / 2, two.height / 2);
  r1.scale = 0.62;

  two.bind('update', (frameCount, timeDelta) => {
    // if (!APP.isTouching) {
    //   two.pause();

    //   return;
    // }

    // angle = angle > 0 ? angle - 1 : angle + 1;
    angle += 1;

    // if (!isMouseActive) {
    if (position.distanceTo(mouse) < radius) {
      isInRadius = true;
    // }
    } else {
      isInRadius = false;
    }

    if (isInRadius && !isMouseActive) {

      if (!isRadiusEntered) {
        isRadiusEntered = true;

        const dot = circling.dot(acceleration);

        if ((dot >= -360 && dot <= -90) || (dot >= 0 && dot <= 90)) {
          angle = dot - 45;
        } else {
          angle = dot + 45;
        }

        // if (dot < -135) {
        //   angle = dot - 135;
        // } else if (dot < 0) {
        //   angle = dot + 135;
        // } else if (dot < 135) {
        //   console.log('nula')
        //   angle = dot - 135;
        // } else {
        //   angle = dot + 135;
        // }
        // angle = circling.dot(acceleration) <= -135 ? circling.dot(acceleration) - 135 : circling.dot(acceleration) + 135;
        // console.log(circling.dot(acceleration), angle)
      }

      const x = radius * (hover ? .2 : 4) * Math.cos(toRadians(angle));
      const y = radius * (hover ? .2 : 4) * Math.sin(toRadians(angle));
      circling.set(x, y).addSelf(mouse).lerp(mouse, .8);
      destination.copy(circling);
    } else {
      isRadiusEntered = false;
      destination.copy(mouse);
    }

    acceleration
    .copy(destination)
    .subSelf(position)
    .subSelf(velocity);

    if (hover) {
      acceleration.setLength(1.2);
    } else if (!isMouseActive && isInRadius) {
      acceleration.setLength(0.6);
    } else {
      acceleration.setLength(0.2);
    }

    velocity
      .addSelf(acceleration);

    if (velocity.length() > hover ? maxSpeed * 2 : maxSpeed) velocity.setLength(hover ? maxSpeed * 2 : maxSpeed);

    position
      .addSelf(velocity);

    delta
      .copy(mouse)
      .subSelf(position);

    // smooth transition between orientations (2 degrees per frame)
    if (!isInRadius || hover) {
      orientation = orientation === 0 ? orientation : orientation + 2;
    } else {
      if (position.distanceTo(mouse) > radius) {
        orientation = orientation === 0 ? orientation : orientation + 2;
      } else {
        const oriRot = isMouseActive ? -45 : -90;

        if (orientation < oriRot) {
          orientation = orientation === oriRot ? orientation : orientation + 2;
        } else {
         orientation = orientation === oriRot ? orientation : orientation - 2;
        }
      }
    }

    const rotation = Math.atan2(delta.y, delta.x);

    r1.rotation = rotation + toRadians(orientation);
    r1.translation.copy(position);
  }).play();

  function idle() {
    if (!isMouseActive) return;
    isMouseActive = false;
    //callback();
  }

  function activate() {
    if (!isMouseActive) {
      isMouseActive = true;
      //activeCallback();
    }
    clearTimeout(timer);
    timer = setTimeout(idle, 200);
  }

  document.addEventListener('mousemove', (event) => {
    mouse.set(event.clientX, event.clientY);

    // isInRadius = false;

    activate();
  });

  document.addEventListener('touchstart', (event) => {
    if (event.targetTouches)
      mouse.set(event.targetTouches[0].clientX, event.targetTouches[0].clientY);
    // console.log(event)

    // isInRadius = false;

    activate();
  });

  const $triggers = document.querySelectorAll('a, button, input, [data-action="hover>fish"]');
  if ($triggers.length) {
    $triggers.forEach($trigger => {
      $trigger.addEventListener('mouseenter', () => {
        hover = true;
      });
      $trigger.addEventListener('mouseleave', () => {
        hover = false;
      });
    });
  }
}

export default fish;
