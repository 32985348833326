import Flickity from 'flickity';
import LazyLoad from 'vanilla-lazyload';

import LocalPageNavigation from './localPageNavigation';
import Lightbox from './lightbox';
import idleTimer from './idle-timer';
import fish from './fish';
import checkTouch from './check-touch';

import { _toggleState } from './utilities';

import APP from './mutilator';

const domLoad = () => {
  const $body = document.body;
  // const windowWidth = window.outerWidth;
  const $header = document.querySelector('#header');
  const $navigation = document.querySelector('#navigation');
  const $navigationToggle = document.querySelector('[data-toggle="navigation"]');

  // check if user is touching
  checkTouch();

   // https://github.com/verlok/lazyload
   const lazyLoad = new LazyLoad({
    elements_selector: '[data-lazy]',
  });

  // fish cursor
  console.log('APP.isTouching', APP.isTouching)
  // if (!APP.isTouching) {
    fish();
  // }

  const $flickitys = document.querySelectorAll('[data-controller="carousel"]');
  if ($flickitys.length > 0) {

    $flickitys.forEach($flickity => {

      const flickity = new Flickity($flickity, {
        // arrowShape: 'M0 .5h98.79L86.6 12.69',
        autoPlay: false,
        cellAlign: 'center',
        cellSelector: '.carousel__cell',
        contain: false,
        pageDots: false,
        dragThreshold: 1,
        freeScroll: true,
        // fullscreen: true,
        // groupCells: true,
        percentPosition: false,
        prevNextButtons: false,
        wrapAround: true,
      });

      // const $cellElements = flickity.getCellElements();
      // $cellElements.forEach($cellElement => {
      //   $cellElement.addEventListener('click', () => {
      //     flickity.viewFullscreen();
      //   });
      // });

      flickity.on('dragStart', (event, pointer) => {
        APP.flickity.drag = true;
      });
      flickity.on('dragEnd', (event, pointer) => {
        setTimeout(() => {
          APP.flickity.drag = false;
        }, 50);
      });

      const $navButtons = document.querySelectorAll('[data-action^="click>carousel#"]');
      if ($navButtons.length > 0) {
        $navButtons.forEach($navButton => {

          $navButton.addEventListener('click', () => {
            switch ($navButton.dataset.action) {
              case 'click>carousel#previous':
                flickity.previous();
                break;

              case 'click>carousel#next':
                flickity.next();
                break;

              default:
                break;
            }
          })

        });
      }
    });
  }

  // Navigation
  $navigationToggle.addEventListener('click', (event) => {
    event.preventDefault();
    _toggleState($navigation, 'closed', 'open');

    if (window.innerWidth <= 992) {
      $body.classList.toggle('no-scroll');
    }
  }, false);

  // jump.js — Animated on page scroll
  const $localPageAnchors = document.querySelectorAll('a[href^="#"]');
  if ($localPageAnchors.length > 0) {
    $localPageAnchors.forEach($localPageAnchor => {
      new LocalPageNavigation($localPageAnchor);
    });
  }

  const $lightboxToggles = document.querySelectorAll('[data-action="click>lightbox#toggle"]');
  if ($lightboxToggles) {
    new Lightbox($lightboxToggles);

    idleTimer({
      // function to fire after idle
      callback: () => $body.classList.add('is-idle'),
      // function to fire when active
      activeCallback: () => $body.classList.remove('is-idle'),
      // Amount of time in milliseconds before becoming idle. default 60000
      idleTime: 2000,
    });
  }
};

export default domLoad;
