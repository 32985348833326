import APP from './mutilator';


function isTouchEnabled() {
  return ('ontouchstart' in window) ||
         (navigator.maxTouchPoints > 0) ||
         (navigator.msMaxTouchPoints > 0);
}

// Is touch: mql.matches === true
function isTouchOnly() {
  return window.matchMedia('(hover: none)').matches;
}


const checkTouch = () => {
  // console.log('isTouchOnly()', isTouchOnly())
  // console.log('window.innerWidth <= 1024', window.innerWidth <= 1024)
  // console.log('isTouchEnabled()', isTouchEnabled())

  if (
      isTouchOnly()
      || (window.innerWidth <= 1024 && isTouchEnabled())
    ) {

    APP.isTouching = true;
  }

  // window.addEventListener('touchstart', function onFirstTouch() {
  //   // we could use a class
  //   document.body.classList.add('is-touching');

  //   // or set some global variable
  //   APP.isTouching = true;

  //   // // or set your app's state however you normally would
  //   // myFrameworkOfChoice.dispatchEvent('USER_IS_TOUCHING', true);

  //   // we only need to know once that a human touched the screen, so we can stop listening now
  //   window.removeEventListener('touchstart', onFirstTouch, false);
  // }, false);
};

export default checkTouch;
